import gsap from "gsap";
import { AbstractTemplate } from "./AbstractTemplate";

export class DropDownTemplate extends AbstractTemplate {
  private static get = (): Node => {
    return DropDownTemplate.getTemplate("#menu_dropdown_template");
  };

  public static createItem = () => {
    const menuWrapper = document.querySelector<HTMLElement>(".menu-dropdown");

    if (menuWrapper) return;

    document.body.appendChild(this.get());
    gsap.to(document.querySelector<HTMLElement>(".menu-dropdown"), { y: 0, duration: 0.3, ease: "power2.out" });
  };

  public static removeItem = async () => {
    const menuWrapper = document.querySelector<HTMLElement>(".menu-dropdown");

    if (!menuWrapper) return;

    await gsap.to(menuWrapper, {
      y: "-100%",
      duration: 0.2,
      ease: "circ.out",
      onComplete: () => {
        gsap.set(menuWrapper, { clearProps: "all" });
      },
    });
    menuWrapper.remove();
  };
}
