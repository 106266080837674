import { gsap } from "gsap";

export default class FadeInAnimation {
  static animate(element: HTMLElement | null, duration: number = 0.5, displayType: string = "block", delay: number = 0): gsap.core.Tween {
    if (!element) {
      throw new Error(`Element not found: ${element}`);
    }

    const currentDisplay = window.getComputedStyle(element).display;

    const fromOpacity = currentDisplay === "none" ? 0 : undefined;

    const tween = gsap.fromTo(element, { opacity: fromOpacity }, { opacity: 1, duration, delay });

    if (currentDisplay === "none") {
      tween.eventCallback("onStart", () => {
        element.style.display = displayType;
      });
    }

    tween.eventCallback("onComplete", () => {
      element.style.display = displayType;
    });

    return tween;
  }
}
