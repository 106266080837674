import { Builder, subMenus } from "./Builder";

export default class MenuHandlerDesktop {
  private navElement: HTMLElement | null;
  private itemsWithChildren: NodeListOf<HTMLLIElement>;

  private menuBuilder: Builder | null = null;

  constructor(private readonly root: HTMLElement) {
    this.navElement = this.root.querySelector(".site-navigation");
    this.itemsWithChildren = this.root.querySelectorAll<HTMLLIElement>(".menu-item.has-children");
    this.init();
  }

  public init = async () => {
    this.initializeClasses();
    this.addEventListeners();
  };

  public destroy = async () => {
    this.removeAllSubMenus();
    this.removeEventListeners();
  };

  private getItemData = (item: HTMLLIElement) => {
    if (!this.navElement?.dataset.menu) return;

    const itemId = item.dataset.id;
    const menuData = JSON.parse(this.navElement?.dataset.menu);
    const [itemData] = [...menuData].filter((data) => data.menuId == itemId);

    return itemData;
  };

  private initializeClasses = () => {
    this.itemsWithChildren.forEach((item) => {
      const data = this.getItemData(item);

      if (!data) {
        return;
      }

      this.menuBuilder = new Builder(item, data);
      this.menuBuilder.init();
    });
  };

  private addEventListeners = () => {
    this.itemsWithChildren.forEach((item) => {
      const toggleButton = item.querySelector(".submenu-toggle");

      if (toggleButton) {
        toggleButton.addEventListener("click", (e) => {
          e.preventDefault();
          this.toggleSubMenu(item);
        });
      }
    });
  };

  private removeEventListeners = () => {
    this.itemsWithChildren.forEach((item) => {
      const toggleButton = item.querySelector(".submenu-toggle");

      if (toggleButton) {
        toggleButton.removeEventListener("click", (e) => {
          e.preventDefault();
          this.toggleSubMenu(item);
        });
      }
    });
  };
 
  private toggleSubMenu = (item: HTMLLIElement) => {
    const isOpen = item.classList.contains("submenu-open");

    if (isOpen) {
      this.closeSubMenu(item);
    } else {
      this.openSubMenu(item);
    }
  };

  private openSubMenu = (item: HTMLLIElement) => {
    item.classList.add("submenu-open");
    const submenu = item.querySelector("ul");

    if (submenu) {
      submenu.style.maxHeight = `${submenu.scrollHeight}px`;
    }
  };

  private closeSubMenu = (item: HTMLLIElement) => {
    item.classList.remove("submenu-open");
    const submenu = item.querySelector("ul");

    if (submenu) {
      submenu.style.maxHeight = "0";
    }
  };

  private removeAllSubMenus = async () => {
    if (subMenus.size === 0) return;

    subMenus.forEach((item) => {
      item.destroy();
    });

    subMenus.clear();
  };
}
