import { AbstractTemplate } from "./AbstractTemplate";
import gsap from "gsap";

interface IItem {
  title: string;
  url: string;
  active: boolean;
  children: [];
  banner: Array<number>;
  classes: string | boolean;
  target: string | boolean;
  menuId: number;
}

let anchorItems = new Map<HTMLAnchorElement, IItem>();

export class ItemTemplate extends AbstractTemplate {
  private static get = (): Node => {
    return ItemTemplate.getTemplate("#menu_dropdown_item");
  };

  private static fill = (data: IItem): Node => {
    const template = this.get() as HTMLLIElement;
    const liElement = template.querySelector("li") as HTMLLIElement;

    const classes = (data.classes as string) ?? null;
    const classArr = classes ? classes.split(" ").filter((className) => className.trim() !== "") : "";

    if (data.url === "#" && data.title) return this.createSpanElement(data, template, liElement, classArr);

    return this.createAnchorElement(data, template, liElement, classArr);
  };

  private static createChildChain = (data: IItem, liElement: HTMLLIElement) => {
    if (data.children.length > 0) {
      // Jeśli element ma dzieci, dodajemy klasę "has-children" do li
      liElement.classList.add("has-children");

      const subList = document.createElement("ul");

      data.children.forEach((child) => {
        subList.appendChild(this.fill(child));
      });

      liElement.append(subList);
    }
  };

  private static createSpanElement = (data: IItem, template: HTMLElement, liElement: HTMLLIElement, classes: string[] | ""): Node => {
    const anchor = template.querySelector("a");
    const spanElement = template.querySelector("span");

    if (!spanElement) return template;

    anchor?.remove();
    spanElement.textContent = data.title as string;
    if (liElement && classes) liElement.classList.add(...classes);
    this.createChildChain(data, liElement);

    return template;
  };

  private static createAnchorElement = (data: IItem, template: HTMLElement, liElement: HTMLLIElement, classes: string[] | ""): Node => {
    const anchor = template.querySelector("a");
    const spanElement = template.querySelector("span");

    if (!anchor) return template;

    spanElement?.remove();

    anchor!.href = data.url ?? "#";
    anchor.title = data.title ?? "";
    anchor.target = data.target === false ? "_self" : (data.target as string) ?? "_self";
    anchor.textContent = data.title ?? "";
    if (liElement && classes) liElement.classList.add(...classes);

    this.listeners(anchor, data);
    this.addAnchor(anchor, data);

    this.createChildChain(data, liElement);

    return template;
  };

  public static createItem = (data: IItem): Node => {
    return this.fill(data);
  };

  public static removeItem = async () => {
    anchorItems.forEach((data, item) => {
      this.destroyListeners(item, data);
    });
  };

  private static changeImage = async (data: IItem) => {
    const imageWrapper = document.querySelector(".menu-dropdown .menu-dropdown__image") as HTMLElement;
    const [bannerSrc = "" as string, width = 0, height = 0] = data.banner || [];
    if (!imageWrapper) return;

    const image = imageWrapper.querySelector("img") as HTMLImageElement;
    if (!image || !bannerSrc || image.src === bannerSrc || data.menuId === parseInt(imageWrapper.dataset.itemId as string)) return;

    gsap.to(image, { opacity: 0, duration: 0.15 });

    image.src = bannerSrc as string;
    image.width = width;
    image.height = height;
    imageWrapper.setAttribute("data-item-id", `${data.menuId}`);

    gsap.fromTo(image, { opacity: 0 }, { opacity: 1, duration: 0.2 });
  };

  private static listeners = (item: HTMLAnchorElement, data: IItem) => {
    if (!item || item.classList.contains("item-has-mouse-event")) return;

    item.addEventListener("mouseenter", this.mouseEnterHandler.bind(this, item, data));
  };

  private static destroyListeners = (item: HTMLAnchorElement, data: IItem) => {
    if (!item) return;

    item.removeEventListener("mouseenter", this.mouseEnterHandler.bind(this, item, data));
  };

  private static mouseEnterHandler = (item: HTMLAnchorElement, data: IItem) => {
    if (!item || !data) return;
    item.classList.add("item-has-mouse-event");
    this.changeImage(data);
  };

  private static addAnchor = (item: HTMLAnchorElement, data: IItem) => {
    if (!anchorItems.has(item)) {
      anchorItems.set(item, data);
    }
  };
}
