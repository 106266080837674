export class Timeout {
  public leaveTimeout: number | undefined;
  public openTimeout: number | undefined;

  public resetAll(): void {
    if (this.leaveTimeout) {
      clearTimeout(this.leaveTimeout);
    }
    if (this.openTimeout) {
      clearTimeout(this.openTimeout);
    }
  }

  public resetLeaveTimeout(): void {
    if (this.leaveTimeout) {
      clearTimeout(this.leaveTimeout);
    }
  }

  public resetOpenTimeout(): void {
    if (this.openTimeout) {
      clearTimeout(this.openTimeout);
    }
  }
}
