
import FadeInAnimation from "../../Modules/AnimationPackModule/FadeInAnimation";
import FadeOutAnimation from "../../Modules/AnimationPackModule/FadeOutAnimation";
import FadeToggleAnimation from "../../Modules/AnimationPackModule/FadeToggleAnimation";
import SlideDownAnimation from "../../Modules/AnimationPackModule/SlideDownAnimation";
import SlideUpAnimation from "../../Modules/AnimationPackModule/SlideUpAnimation";
import SlideToggleAnimation from "../../Modules/AnimationPackModule/SlideToggleAnimation";
import RotateAnimation from "../../Modules/AnimationPackModule/RotateAnimation";
import { Animation, TransformAnimation } from "../../Modules/AnimationPackModule/AnimationTypes";

class AnimationPackModule {
  static fadeIn: Animation = FadeInAnimation;
  static fadeOut: Animation = FadeOutAnimation;
  static fadeToggle: Animation = FadeToggleAnimation;
  static slideDown: Animation = SlideDownAnimation;
  static slideUp: Animation = SlideUpAnimation;
  static slideToggle: Animation = SlideToggleAnimation;
  static rotate: TransformAnimation = RotateAnimation;
}

const APM = AnimationPackModule;

export default APM;