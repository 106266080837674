import { gsap } from "gsap";

export default class FadeToggleAnimation {

  static animate(element: HTMLElement | null, duration: number = 0.5, displayType: string = "block"): gsap.core.Tween {
    if (!element) {
      throw new Error(`Element not found: ${element}`);
    }

    const currentDisplay = window.getComputedStyle(element).display;

    const isHidden = currentDisplay === "none";
    const fromOpacity = isHidden ? 0 : 1;
    const toOpacity = isHidden ? 1 : 0;

    const tween = gsap.fromTo(element, { opacity: fromOpacity }, { opacity: toOpacity, duration });

    if (isHidden) {
      tween.eventCallback("onStart", () => {
        element.style.display = displayType;
      });
    }

    if (!isHidden) {
      tween.eventCallback("onComplete", () => {
        element.style.display = 'none';
      });
    }

    return tween;
  }
}