import { DropDownTemplate } from "./Templates/DropDownTemplate";
import { OverlayTemplate } from "./Templates/OverlayTemplate";
import { BannerTemplate } from "./Templates/BannerTemplate";
import { ColumnTemplate } from "./Templates/ColumnTemplate";
import { ItemTemplate } from "./Templates/ItemTemplate";

interface Template {
  createItem(arg?: any): Promise<void> | Promise<Node> | void | Node;
  removeItem(): void;
}

export class MenuTemplate {
  static overlay: Template = OverlayTemplate;
  static dropdown: Template = DropDownTemplate;
  static banner: Template = BannerTemplate;
  static column: Template = ColumnTemplate;
  static menuItem: Template = ItemTemplate;
}
