// SlideToggleAnimation.ts
import SlideDownAnimation from "./SlideDownAnimation";
import SlideUpAnimation from "./SlideUpAnimation";

export default class SlideToggleAnimation {
  static animate(element: HTMLElement | null, duration: number = 0.5, displayType: string = "block"): gsap.core.Tween {
    if (!element) {
      throw new Error(`Element not found: ${element}`);
    }

    const currentDisplay = window.getComputedStyle(element).display;

    if (currentDisplay === "none") {
      return SlideDownAnimation.animate(element, duration, displayType);
    } else {
      return SlideUpAnimation.animate(element, duration);
    }
  }
}
