import Splide from '@splidejs/splide';
import '@splidejs/splide/css';

export default class MainSlider {
    constructor(hero) {
        this.fadeMainSliderContainer = hero.querySelector('.hero-main-slider');
        this.nameMainSliderContainer = hero.querySelector('.hero-name-slider');
        this.thumbnailMainSliderContainer = hero.querySelector('.hero-thumbnail-carousel');

        this.initMainSlider();
        this.initThumbnailSlider();
        this.initNameSlider();

        this.MainSlider.sync(this.thumbnailSlider);
        this.MainSlider.sync(this.nameSlider);

        this.MainSlider.mount();
        this.thumbnailSlider.mount();
        this.nameSlider.mount();
    }

    initMainSlider = () => {
        this.MainSlider = new Splide(this.fadeMainSliderContainer, {
            type: 'fade',
            pagination: false,
            arrows: false,
            drag: false,
        });
    };

    initThumbnailSlider = () => {
        this.thumbnailSlider = new Splide(this.thumbnailMainSliderContainer, {
            fixedWidth: 270,
            gap: 32,
            pagination: false,
            perMove: 1,
            arrows: false,
            isNavigation: true,
            updateOnMove: true,

            breakpoints: {
                1380: { fixedWidth: 210, gap: 28 },
                820: { fixedWidth: 170, gap: 20 },
                1024: { fixedWidth: 190, gap: 24 },
            },
        });
    };

    initNameSlider = () => {
        this.nameSlider = new Splide(this.nameMainSliderContainer, {
            type: 'fade',
            pagination: false,
            isNavigation: true,
        });
    };

    initListeners = () => {};
}
