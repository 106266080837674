// SlideDownAnimation.ts
import { gsap } from "gsap";
import { getHiddenElementHeight } from "../../Components/Helpers";

export default class SlideDownAnimation {
  static animate(element: HTMLElement | null, duration: number = 0.5, displayType: string = "block"): gsap.core.Tween {
    if (!element) {
      throw new Error(`Element not found: ${element}`);
    }

    const currentDisplay = window.getComputedStyle(element).display;

    if (currentDisplay !== "none") {
      return gsap.to(element, { duration: 0 });
    }

    element.style.opacity = "0";
    element.style.height = "0";
    element.style.display = displayType;

    const targetHeight = getHiddenElementHeight(element);
    const tween = gsap.to(element, { opacity: 1, height: targetHeight, duration });

    tween.eventCallback("onComplete", () => {
      element.style.height = "auto";
    });

    return tween;
  }
}
