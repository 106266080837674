export const cookieGet = (key) => {
  var keyValue = document.cookie.match("(^|;) ?" + key + "=([^;]*)(;|$)");
  return keyValue ? keyValue[2] : null;
};

export const unwrap = (wrapper) => {
  // place childNodes in document fragment
  var docFrag = document.createDocumentFragment();
  while (wrapper.firstChild) {
    var child = wrapper.removeChild(wrapper.firstChild);
    docFrag.appendChild(child);
  }

  // replace wrapper with document fragment
  wrapper.parentNode.replaceChild(docFrag, wrapper);
};

export const disableScroll = () => {
  var x = window.scrollX;
  var y = window.scrollY;

  if (document.body.scrollHeight > window.innerHeight) {
    document.body.style.overflowY = "scroll";
  }

  document.body.classList.add("scroll-locked");

  window.scrollTo(x, y);

  window.onscroll = function () {
    window.scrollTo(x, y);
  };
};

export const enableScroll = () => {
  document.body.style.overflow = "";
  document.body.classList.remove("scroll-locked");
  document.body.style.position = "";
  window.onscroll = function () { };
};

export const toggleScroll = () => {
  if (document.body.classList.contains("scroll-locked")) {
    enableScroll();
    document.body.classList.remove("scroll-locked");
  } else {
    disableScroll();
    document.body.classList.add("scroll-locked");
  }
};

export const isInViewport = (element) => {
  const rect = element.getBoundingClientRect();
  return rect.top >= 0 && rect.left >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && rect.right <= (window.innerWidth || document.documentElement.clientWidth);
};


export const randomBetween = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};


export const orientation = () => {
  if (window.matchMedia("(orientation: portrait)").matches) {
    return "portrait";
  } else {
    return "landscape";
  }
};

export const nodeIndex = (node) => {
  return Array.prototype.indexOf.call(node.parentNode.childNodes, node);
};

export const nodeChildIndex = (node) => {
  return Array.prototype.indexOf.call(node.parentNode.children, node);
};

export const randomString = async (length) => {
  const letters = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPRSTUVWXYZ";
  let result = "";

  for (let i = 0; i < length; i++) {
    result += letters[Math.floor(Math.random() * letters.length)];
  }

  return result;
};

export const debounce = (func, timeout = 500) => {
  let timer;

  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};

export const bindEvents = async (selector, event, callback, node = document) => {
  const elements = node.querySelectorAll(selector);

  elements.forEach((element) => {
    element.addEventListener(event, callback);
  });
};

export const unbindEvents = async (selector, event, callback, node = document) => {
  const elements = node.querySelectorAll(selector);

  elements.forEach((element) => {
    element.removeEventListener(event, callback);
  });
};

export const bindEvent = async (selector, event, callback, node = document) => {
  const element = node.querySelector(selector);

  element && element.addEventListener(event, callback);
};

export const unbindEvent = async (selector, event, callback, node = document) => {
  const element = node.querySelector(selector);

  element && element.removeEventListener(event, callback);
};

export const isIntersecting = (element, cb, rootMargin = "200px"): void => {
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        cb();
        observer.unobserve(element);
      }
    }),
      { root: null, rootMargin };
  });
  observer.observe(element);
};

export const getHiddenElementHeight = (element: HTMLElement, classToAdd = undefined) => {
  const clone = element.cloneNode(true) as HTMLElement;
  const data = element.getBoundingClientRect() as DOMRect;

  clone.style.position = "absolute";
  clone.style.display = "block";
  clone.style.height = "auto";
  clone.style.width = data.width + "px";
  clone.classList.add(classToAdd === undefined ? "open" : classToAdd);
  clone.style.left = "-100000em";
  clone.id = "tmp-" + Math.floor(Math.random() * 99999);

  if (element.parentNode) element.parentNode.appendChild(clone);

  const height = document.getElementById(clone.id)?.offsetHeight;
  clone.remove();
  return height;
};
