import lazyLoadImages from './Components/lazyLoad.js';
import MainSlider from './Components/main-slider.js';
import Tabs from './Components/productsTabs.js';
import NavBar from './Components/navbar.js';
import MenuHandlerDesktop from './ts/Components/Header/Desktop/MenuHandlerDesktop.ts'; 
import MenuHandlerMobile from './ts/Components/Header/Mobile/MenuHandlerMobile.ts'; 
import initAddToCart from './Components/initAddToCart.js';
import Dropdown from './Components/dropdown.js';
import AddToWhishList from './Components/addToWhishList.js'; 
import Cookies from './Components/CookiesPopup.js';
import Popup from './Components/Popup.js'; 
import CategoryDesc from './Components/CategoryDesc.js';

require('fslightbox');

document.addEventListener('DOMContentLoaded', () => {
    lazyLoadImages();

    initNavbar();

    initAsyncAddToCart();

    handleInitDropdown();

    handleWhishList();

    initCategoryDescSections();

    new Cookies();

    new Popup().init();

    initSliders();

    initTabs();
    
    handleResponsiveMenu(); 
});

const initCategoryDescSections = () => {
    document.querySelectorAll('.category-description-section').forEach((section) => {
        new CategoryDesc(section);
    });
};


const initNavbar = async () => {
    return new Promise((resolve, reject) => {
        new NavBar();
        resolve();
    });
};


const initAsyncAddToCart = async () => {
    new initAddToCart();
};


const handleInitDropdown = async () => {
    try {
        const dropdowns = await isUserLogged();
        await initDropdown(dropdowns);
    } catch (error) {}
};

const isUserLogged = () => {
    return new Promise((resolve, reject) => {
        const dropdowns = document.querySelectorAll('.dropdown-list');
        dropdowns.length > 0 ? resolve(dropdowns) : reject();
    });
};

const initDropdown = (dropdowns) => {
    return new Promise((resolve, reject) => {
        dropdowns.forEach((dropdown) => {
            new Dropdown(dropdown);
        });
        resolve();
    });
};

const initSliders = () => {
    const mainSliders = document.querySelectorAll('.hero-slider');

    if (mainSliders.length > 0) {
        mainSliders.forEach((slider) => {
            new MainSlider(slider);
        });
    }
};

const initTabs = () => {
    const tabs = document.querySelectorAll('.section-tabs');

    if (tabs.length > 0) {
        tabs.forEach((section) => {
            new Tabs(section);
        });
    }
};

const handleResponsiveMenu = () => {
    const desktopMenuHandler = () => {
        const menuRoot = document.querySelector('.header-desktop');
        if (menuRoot) {
            new MenuHandlerDesktop(menuRoot);
        }
    };

    const mobileMenuHandler = () => {
        const menuRoot = document.querySelector('.header-desktop');
        if (menuRoot) {
            new MenuHandlerMobile(menuRoot);
        } 
    };

    if (window.innerWidth > 1024) {
        desktopMenuHandler(); 
    } else {
        mobileMenuHandler();  
    }

    window.addEventListener('resize', () => {
        if (window.innerWidth > 1024) {
            desktopMenuHandler();
        } else {
            mobileMenuHandler();  
        }
    });
};

const handleWhishList = async () => {
    if (document.querySelector('.yith-wcwl-form ')) {
        jQuery(document).on('ajaxComplete', (e) => {
            initAsyncAddToCart();
        });
    } 
};
