import { MenuTemplate } from "../Template";
import { AbstractTemplate } from "./AbstractTemplate";
import gsap from "gsap";

interface IColumn {
  children: [];
}

export class ColumnTemplate extends AbstractTemplate {
  private static get = (): Node => {
    return ColumnTemplate.getTemplate("#menu_dropdown_column");
  };

  public static createItem = async (data: IColumn) => {
    const wrapper = document.querySelector(".menu-dropdown .menu-dropdown__content");

    if (!wrapper || wrapper?.innerHTML.trim() !== "") return;

    data.children.forEach((child) => {
      const template = this.get() as HTMLElement;
      const list = template.querySelector("ul") as HTMLUListElement;

      list.appendChild(MenuTemplate.menuItem.createItem(child) as Node);
      wrapper.appendChild(list);
    });

    await gsap.fromTo(wrapper, { opacity: 0, y: "-12px" }, { opacity: 1, y: 0, duration: 0.3, delay: 0.2 });
  };

  public static removeItem = async () => {
    const wrapper = document.querySelector(".menu-dropdown .menu-dropdown__content");

    if (!wrapper) return;

    const lists = wrapper.querySelectorAll(":scope > ul");

    lists.forEach((list) => {
      list.innerHTML = "";
      list.remove();
    });
    MenuTemplate.menuItem.removeItem();

    gsap.fromTo(wrapper, { opacity: 1 }, { opacity: 0, duration: 0.3 });
  };
}
