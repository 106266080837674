import { gsap } from "gsap";

export default class FadeOutAnimation {

  static animate(element: HTMLElement | null, duration: number = 0.5): gsap.core.Tween {
    if (!element) {
      throw new Error(`Element not found: ${element}`);
    }

    const tween = gsap.fromTo(element, { opacity: 1 }, { opacity: 0, duration });

    tween.eventCallback("onComplete", () => {
      element.style.display = 'none';
    });

    return tween;
  }
}