import APM from "../../AnimationPackModule";
import { AbstractTemplate } from "./AbstractTemplate";

interface IBanner {
  title: string;
  banner: Array<number>;
}

export class BannerTemplate extends AbstractTemplate {
  private static get = (): Node => {
    return BannerTemplate.getTemplate("#menu_dropdown_banner");
  };

  private static fill = (data: IBanner): Node => {
    const template = this.get() as HTMLElement;

    const imageItem = template.querySelector("img") as HTMLImageElement;

    if (!(data.banner && data.banner.length > 0 && imageItem)) {
      const pictureTag = template.querySelector("picture") as HTMLPictureElement;
      imageItem.remove();
      pictureTag.remove();

      return document.createElement("div");
    }

    const [bannerSrc = "", width = 0, height = 0] = data.banner || [];

    imageItem.src = bannerSrc as string;
    imageItem.width = width;
    imageItem.height = height;
    imageItem.alt = data.title;

    return template;
  };

  public static createItem = async (data: IBanner) => {
    const bannerTemplate = this.fill(data);
    const imageWrapper = document.querySelector(".menu-dropdown .menu-dropdown__image") as HTMLElement;

    if (!imageWrapper) return;

    const image = imageWrapper.querySelector("img");
    if (image) return;

    imageWrapper.append(bannerTemplate);
    await APM.fadeIn.animate(imageWrapper.querySelector("img"), 0.4, "block", 0.2);
  };

  public static removeItem = async () => {
    const imageWrapper = document.querySelector(".menu-dropdown .menu-dropdown__image") as HTMLElement;
    if (!imageWrapper) return;

    const image = imageWrapper.querySelector("img");
    if (!image) return;

    await APM.fadeOut.animate(image, 0.2);
    image.remove();
  };
}
