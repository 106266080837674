import APM from "../../AnimationPackModule";
import { AbstractTemplate } from "./AbstractTemplate";

export class OverlayTemplate extends AbstractTemplate {
  private static get = (): Node => {
    return OverlayTemplate.getTemplate("#menu_overlay_template");
  };

  public static createItem = async () => {
    const overlay = document.querySelector<HTMLElement>(".menu-overlay");
    if (!overlay) {
      document.body.appendChild(this.get());
      APM.fadeIn.animate(document.querySelector<HTMLElement>(".menu-overlay"), 0.2);
    }
  };

  public static removeItem = async () => {
    const overlay = document.querySelector<HTMLElement>(".menu-overlay");
    if (overlay) {
      await APM.fadeOut.animate(overlay, 0.2);
      overlay.remove();
    }
  };
}
