import gsap from "gsap";
import { enableScroll, disableScroll } from "../../Helpers";

export default class MenuHandlerMobile {
  private itemsWithChildren: NodeListOf<HTMLLIElement>;
  private toggleButton: HTMLButtonElement | null;
  private menu: HTMLElement | null;
  private isOpen: boolean = false;

  constructor(private readonly root: HTMLElement) {
    this.toggleButton = this.root.querySelector<HTMLButtonElement>(".header-hamburger");
    this.itemsWithChildren = this.root.querySelectorAll<HTMLLIElement>(".menu-item.has-children");
    this.menu = this.root.querySelector<HTMLElement>(".site-navigation");
    this.initializeSubMenu();
  }

  public init = async () => {
    this.initializeSubMenu();
    this.listeners();
  };

  public destroy = async () => {
    this.isOpen = false;
    this.removeListeners();
    this.closeAllSubmenus();
    this.toggleButton?.classList.remove("active");
    this.toggleButton?.classList.add("not-active");
    this.menu?.classList.remove("active");
    enableScroll();
  };

  private initializeSubMenu = () => {
    this.itemsWithChildren.forEach((item) => {
      const toggle = item.querySelector(".menu-item-toggle");
      const submenu = item.querySelector(".submenu");

      // if (submenu) {
      //   gsap.set(submenu, { height: 0, display: "none" }); // Submenu domyślnie ukryte
      // }

      if (toggle) {
        toggle.addEventListener("click", (event) => {
          event.stopPropagation(); // Zapobiega propagacji do nadrzędnych elementów
          this.toggleSubMenu(item);
        });
      }
    });
  };

  private toggleMenu = () => {
    this.isOpen = !this.isOpen;
    this.menu?.classList.toggle("active", this.isOpen);
    this.hamburgerAnimation();
    if (!this.isOpen) {
      this.closeAllSubmenus();
      enableScroll();
    } else {
      disableScroll();
    }
  };

  private toggleSubMenu = (item: HTMLLIElement) => {
    const submenu = item.querySelector<HTMLElement>(".submenu");

    if (submenu) {
      const isActive = item.classList.contains("active");

      // Zamykamy obecne submenu
      if (isActive) {
        item.classList.remove("active");
        gsap.to(submenu, {
          height: 0,
          duration: 0.3,
          display: "none",
          ease: "circ.inOut",
        });
      } else {
        // Zamykamy inne otwarte submenu na tym samym poziomie
        const parentMenu = item.parentElement;
        if (parentMenu) {
          parentMenu.querySelectorAll(".menu-item.active").forEach((activeItem) => {
            activeItem.classList.remove("active");
            const activeSubmenu = activeItem.querySelector<HTMLElement>(".submenu");
            if (activeSubmenu) {
              gsap.to(activeSubmenu, {
                height: 0,
                duration: 0.3,
                display: "none",
                ease: "circ.inOut",
              });
            }
          });
        }

        // Otwieramy aktualnie kliknięte submenu
        item.classList.add("active");
        gsap.set(submenu, { display: "block" });
        gsap.fromTo(
          submenu,
          { height: 0 },
          {
            height: "auto",
            duration: 0.3,
            ease: "circ.inOut",
          }
        );
      }
    }
  };

  private closeAllSubmenus = () => {
    this.itemsWithChildren.forEach((item) => {
      item.classList.remove("active");
      const submenu = item.querySelector<HTMLElement>(".submenu");
      if (submenu) {
        gsap.to(submenu, {
          height: 0,
          duration: 0.3,
          display: "none",
          ease: "circ.inOut",
        });
      }
    });
  };

  private hamburgerAnimation = () => {
    this.toggleButton?.classList.toggle("active");
    this.toggleButton?.classList.toggle("not-active");
  };

  private listeners = () => {
    this.toggleButton?.addEventListener("click", this.toggleMenu);
  };

  private removeListeners = () => {
    this.toggleButton?.removeEventListener("click", this.toggleMenu);
  };
}
