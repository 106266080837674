import { enableScroll, disableScroll } from "../../../Components/Helpers";
import { MenuTemplate } from "./Template";
import { Timeout } from "./Timeout";
import { Config } from "./Config";
import { State } from "./State";

export let subMenus = new Map<HTMLLIElement, Builder>();

export class Builder {
  private timeout: Timeout = new Timeout();
  private state: State = new State();
  private header: HTMLElement | null;

  constructor(private root: HTMLLIElement, private itemData: JSON) {
    this.header = this.root.closest(".header");
  }

  public init = async () => {
    this.addSubMenu();
    this.listeners();
  };

  public destroy = async () => {
    this.destroyListeners();
    this.removeMenu();
  };

  private buildMenu = () => {
    
    this.timeout.resetAll();

    this.timeout.openTimeout = window.setTimeout(() => {

      disableScroll();
      this.header?.classList.add("menu-open");
      this.root.classList.add("active-item");

      MenuTemplate.overlay.createItem();
      MenuTemplate.dropdown.createItem();
      MenuTemplate.banner.createItem(this.itemData);
      MenuTemplate.column.createItem(this.itemData);

      this.initializeMenuListeners();
    }, Config.delayOpenMenu);
  };

  private removeMenu = () => {
    this.timeout.resetAll();

    if (this.state.isAppendedMenuHovered || this.state.isActiveTopHovered) return;

    this.timeout.leaveTimeout = window.setTimeout(() => {
      this.timeout.leaveTimeout = undefined;
      this.timeout.openTimeout = undefined;

      MenuTemplate.overlay.removeItem();
      MenuTemplate.dropdown.removeItem();
      MenuTemplate.banner.removeItem();
      MenuTemplate.column.removeItem();

      this.removeMenuListeners();

      this.header?.classList.remove("menu-open");
      this.root.classList.remove("active-item");
      this.state.reset();
      enableScroll();
    }, Config.delayCloseMenu);
  };

  private destroyListeners = () => {
    this.root.removeEventListener("mouseenter", this.mouseEnterHandler);
    this.root.removeEventListener("mouseleave", this.mouseLeaveHandler);
  };

  private listeners = () => {
    this.root.addEventListener("mouseenter", this.mouseEnterHandler);
    this.root.addEventListener("mouseleave", this.mouseLeaveHandler);
  };

  private mouseEnterHandler = () => {
    disableScroll();
    this.state.isActiveTopHovered = true;
    this.buildMenu();
  };

  private mouseLeaveHandler = () => {
    this.state.isActiveTopHovered = false;
    this.removeMenu();
  };

  private initializeMenuListeners = () => {
    const menu = document.querySelector<HTMLElement>(".menu-dropdown");

    if (!menu || menu.classList.contains("has-mouse-event")) return;

    menu.classList.add("has-mouse-event");

    menu.addEventListener("mouseenter", () => {
      this.state.isAppendedMenuHovered = true;
      this.timeout.resetLeaveTimeout();
    });
    menu.addEventListener("mouseleave", () => {
      this.state.isAppendedMenuHovered = false;
      this.removeMenu();
    });
  };

  private removeMenuListeners = () => {
    const menu = document.querySelector<HTMLElement>(".menu-dropdown");
    if (!menu) return;

    menu.classList.remove("has-mouse-event");

    menu.removeEventListener("mouseenter", () => {
      this.state.isAppendedMenuHovered = true;
      this.timeout.resetLeaveTimeout();
    });
    menu.removeEventListener("mouseleave", () => {
      this.state.isAppendedMenuHovered = false;
      this.removeMenu();
    });
  };

  private addSubMenu = () => {
    if (!subMenus.has(this.root)) {
      subMenus.set(this.root, this);
    }
  };
}
