import { gsap } from "gsap";

export default class RotateAnimation {

  static animate(element: HTMLElement | null, deg: number = 180, duration: number = 0.5): gsap.core.Tween {
    if (!element) {
      throw new Error(`Element not found: ${element}`);
    }

    const tween = gsap.to(element, { rotate: deg, duration });

    return tween;
  }
}